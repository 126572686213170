import Head from "next/head";
import styles from "@/styles/Login.module.css";
import React, { useEffect } from "react";
import { setToken } from "@/lib/authentication";
import { useRouter } from "next/router";
import { useStore, globalStore } from "@/lib/store";
import request, { RequestMethod } from "@/lib/requestClient";

// Hack to prevent react strict mode (in dev) from double-requesting the token causing it to invalidate on the second request
var disableStrict = false;

export default function Login() {
  const router = useRouter();
  const [user, updateUser] = useStore("userStore", ({ user }) => user);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const redirectTo = urlParams.get("redirect-to")
      ? urlParams.get("redirect-to")!!
      : "/";

    if (code) {
      if (disableStrict) {
        return;
      }
      disableStrict = true;
      fetch(process.env.NEXT_PUBLIC_OIDC_URI + "/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "authorization_code",
          client_id: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID!!,
          code: code,
          redirect_uri:
            window.location.origin +
            "/login" +
            (redirectTo
              ? "?redirect-to=" + encodeURIComponent(redirectTo)
              : ""),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            console.error(data.error);
            return;
          }
          disableStrict = false;
          setToken(data.access_token, data.id_token, data.refresh_token);
          const endpoint = request.endpoint(`auth/me`);
          request
            .call(endpoint, request.createDefaultParams(RequestMethod.GET))
            .then((response) => response.json())
            .then((data) => {
              updateUser({ user: data, organization: data.organizations[0] });
              if (window.heap) {
                window.heap.identify(data.email);
              }
              router.replace(redirectTo);
            });
        });
    } else {
      router.replace(
        process.env.NEXT_PUBLIC_OIDC_URI +
          "/auth?client_id=frontend&redirect_uri=" +
          encodeURIComponent(
            window.location.origin +
              "/login" +
              (redirectTo
                ? "?redirect-to=" + encodeURIComponent(redirectTo)
                : "")
          ) +
          "&scope=openid&response_type=code&response_mode=query&prompt=login"
      );
    }
  }, []);

  return (
    <>
      <main className={styles.main}>
        <Head>
          <title>Login</title>
          <meta name="description" content="Bismuth" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
      </main>
    </>
  );
}
